import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css'; // Optional for custom styles
import logo from './assets/logo.jpg'; // Import the logo

const LandingPage = () => {
  return (
    <div className="landing-page">
      <header className="landing-header">
        <img src={logo} alt="Website Logo" className="logo" /> {/* Add logo */}
        <h1>Welcome to Bill Splitter App</h1>
        <p>
          Easily split bills among friends, family, or colleagues.
          Customize the way you split bills, including tips, tax, and more.
        </p>
      </header>

      <section className="landing-content">
        <h2>How It Works</h2>
        <p>
          Just enter the number of people, add items, and let our app calculate
          how much each person owes. You can even split the tip and tax!
        </p>
        <Link to="/bill-splitter">
          <button className="get-started-btn">Get Started</button>
        </Link>
      </section>
    </div>
  );
};

export default LandingPage;
