import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import BillSplitter from './BillSplitter'; // Import your bill splitter component

function App() {
  return (
    <Router>
      <Routes>
        {/* Landing page route */}
        <Route exact path="/" element={<LandingPage />} />

        {/* Bill splitter page route */}
        <Route path="/bill-splitter" element={<BillSplitter />} />
      </Routes>
    </Router>
  );
}

export default App;
