import React, { useState } from 'react';
import './App.css'; // Add your custom CSS file

function BillSplitter() {
  const [people, setPeople] = useState([]);
  const [numPeople, setNumPeople] = useState(0);
  const [tip, setTip] = useState(0);
  const [tax, setTax] = useState(0);

  // Handle the number of people input
  const handleNumPeopleChange = (e) => {
    setNumPeople(e.target.value);
  };

  // Handle adding people based on the number input
  const handleAddPeople = () => {
    let initialPeople = [];
    for (let i = 0; i < numPeople; i++) {
      initialPeople.push({ name: `Person ${i + 1}`, items: [], totalCost: 0 });
    }
    setPeople(initialPeople);
  };

  // Handle tip and tax input
  const handleTipChange = (e) => {
    setTip(parseFloat(e.target.value) || 0);
  };

  const handleTaxChange = (e) => {
    setTax(parseFloat(e.target.value) || 0);
  };

  // Handle adding items to a specific person
  const handleAddItem = (index, item) => {
    const newPeople = [...people];
    newPeople[index].items.push(item);
    newPeople[index].totalCost += item.cost * item.quantity;
    setPeople(newPeople);
  };

  // Handle item deletion
  const handleDeleteItem = (personIndex, itemIndex) => {
    const newPeople = [...people];
    const item = newPeople[personIndex].items[itemIndex];
    newPeople[personIndex].totalCost -= item.cost * item.quantity;
    newPeople[personIndex].items.splice(itemIndex, 1);
    setPeople(newPeople);
  };

  return (
    <div className="app">
      <h1 className="app-title">Bill Splitter</h1>

      <div className="input-group">
        <input
          type="number"
          placeholder="Enter number of people"
          value={numPeople}
          onChange={handleNumPeopleChange}
          min="2"
          className="input"
        />
        <button className="button" onClick={handleAddPeople}>
          Set People
        </button>
      </div>

      {/* Labelled Tip and Tax Fields */}
      <div className="input-group">
        <label htmlFor="tip">Tip Percentage:</label>
        <input
          id="tip"
          type="number"
          placeholder="Enter tip percentage"
          value={tip}
          onChange={handleTipChange}
          min="0"
          className="input"
        />
      </div>

      <div className="input-group">
        <label htmlFor="tax">Tax Percentage:</label>
        <input
          id="tax"
          type="number"
          placeholder="Enter tax percentage"
          value={tax}
          onChange={handleTaxChange}
          min="0"
          className="input"
        />
      </div>

      {people.length > 0 &&
        people.map((person, index) => (
          <PersonForm
            key={index}
            person={person}
            onAddItem={(item) => handleAddItem(index, item)}
            onDeleteItem={(itemIndex) => handleDeleteItem(index, itemIndex)}
            people={people}
          />
        ))}

      {people.length > 0 && <Summary people={people} tip={tip} tax={tax} />}
    </div>
  );
}

// PersonForm Component for handling each person's items
const PersonForm = ({ person, onAddItem, onDeleteItem, people }) => {
  const [itemName, setItemName] = useState('');
  const [itemCost, setItemCost] = useState(''); // Start blank
  const [itemQuantity, setItemQuantity] = useState(''); // Start blank
  const [sharedWith, setSharedWith] = useState([]); // Keep track of which people are sharing the item

  const handleAddItemClick = () => {
    if (itemName && itemCost && itemQuantity) {
      onAddItem({
        name: itemName,
        cost: parseFloat(itemCost),
        quantity: parseInt(itemQuantity),
        sharedWith: sharedWith.length > 0 ? sharedWith : null,
      });
      setItemName('');
      setItemCost(''); // Clear input after adding item
      setItemQuantity(''); // Clear input after adding item
      setSharedWith([]);
    }
  };

  const handleSharingChange = (e) => {
    const personIndex = parseInt(e.target.value);
    if (sharedWith.includes(personIndex)) {
      setSharedWith(sharedWith.filter((p) => p !== personIndex));
    } else {
      setSharedWith([...sharedWith, personIndex]);
    }
  };

  return (
    <div className="person-card">
      <h3 className="person-name">{person.name}</h3>
      <div className="input-group">
        <input
          type="text"
          placeholder="Item Name"
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
          className="input"
        />
        <input
          type="number"
          placeholder="Item Cost"
          value={itemCost}
          onChange={(e) => setItemCost(e.target.value)}
          className="input"
        />
        <input
          type="number"
          placeholder="Quantity"
          value={itemQuantity}
          onChange={(e) => setItemQuantity(e.target.value)}
          min="1"
          className="input"
        />
        <button className="button" onClick={handleAddItemClick}>
          Add Item
        </button>
      </div>

      <div>
        <p>Share this item with:</p>
        {people.map((p, index) => (
          <label key={index}>
            <input
              type="checkbox"
              value={index}
              onChange={handleSharingChange}
              checked={sharedWith.includes(index)}
            />
            {p.name}
          </label>
        ))}
      </div>

      <ul className="item-list">
        {person.items.map((item, index) => (
          <li key={index} className="item">
            {item.quantity}x {item.name}: ${item.cost.toFixed(2)} {item.sharedWith && `(Shared with ${item.sharedWith.length} others)`}
            <button className="button button-delete" onClick={() => onDeleteItem(index)}>
              Delete
            </button>
          </li>
        ))}
      </ul>
      <p className="total-cost">Total Cost: ${person.totalCost.toFixed(2)}</p>
    </div>
  );
};

// Summary Component to show the overall bill and how much each person owes
const Summary = ({ people, tip, tax }) => {
  const totalBill = people.reduce((sum, person) => sum + person.totalCost, 0);
  const taxAmount = (tax / 100) * totalBill;
  const tipAmount = (tip / 100) * totalBill;
  const finalBill = totalBill + taxAmount + tipAmount;

  const averagePerPerson = finalBill / people.length;

  const payments = calculatePayments(people, averagePerPerson);

  return (
    <div className="summary">
      <h2 className="summary-title">Summary</h2>
      <p>Total Bill: <strong>${totalBill.toFixed(2)}</strong></p>
      <p>Tax: <strong>${taxAmount.toFixed(2)}</strong></p>
      <p>Tip: <strong>${tipAmount.toFixed(2)}</strong></p>
      <p>Final Total Bill: <strong>${finalBill.toFixed(2)}</strong></p>
      <p>Average Per Person: <strong>${averagePerPerson.toFixed(2)}</strong></p>
      {payments.map(({ debtor, creditor, amount }, index) => (
        <p key={index}>
          {debtor} pays {creditor}: <strong>${amount.toFixed(2)}</strong>
        </p>
      ))}
    </div>
  );
};

// Function to calculate the payments based on debtors and creditors
const calculatePayments = (people, averagePerPerson) => {
  const debtors = [];
  const creditors = [];

  people.forEach((person) => {
    const diff = averagePerPerson - person.totalCost;
    if (diff > 0) {
      debtors.push({ name: person.name, amount: diff });
    } else if (diff < 0) {
      creditors.push({ name: person.name, amount: -diff });
    }
  });

  const payments = [];
  debtors.forEach((debtor) => {
    creditors.forEach((creditor) => {
      const payment = Math.min(debtor.amount, creditor.amount);
      if (payment > 0) {
        payments.push({
          debtor: debtor.name,
          creditor: creditor.name,
          amount: payment,
        });
        debtor.amount -= payment;
        creditor.amount -= payment;
      }
    });
  });

  return payments;
};

export default BillSplitter;
